<template>
  <div class="recruitmentMattersBox">
    <div class="searchBox">
      <div class="searchLeft">
        <ul>
          <li>
            <p class="searchTitle">招聘事项：</p>
            <el-input v-model="title" placeholder="请输入招聘事项"></el-input>
          </li>
          <li>
            <el-button class="searchBtn" type="warning" @click="searchFun">查询</el-button>
          </li>
        </ul>
      </div>
    </div>

    <div class="recruitmentMattersContent">
      <div class="tableBox">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column align="center" type="index" label="序号" width="50"></el-table-column>
          <el-table-column align="center" prop="title" label="招聘事项" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="time" label="报名时间" width="280"></el-table-column>
          <el-table-column align="center" prop="cjr" label="报名状态" width="100">
            <template slot-scope="scope">
              <div>
                <p v-if="scope.row.applyState==0">已开启</p>
                <p v-else>已关闭</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="date" label="审核状态" width="100">
            <template slot-scope="scope">
              <div>
                <p v-if="scope.row.checkState==0">已开启</p>
                <p v-else>已关闭</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="220">
            <template slot-scope="scope">
              <div class="signUpFlex">
                <p class="signUpColor" @click="seeFun(scope.row)">查看详情</p>
                <p class="signUpColor" v-if="scope.row.applyState==1" @click="signUp(scope.row,0)">开启报名</p>
                <p class="signUpColor" v-if="scope.row.checkState==1" @click="toExamine(scope.row,0)">开启审核</p>
                <p class="signUpColor" v-if="scope.row.applyState==0" @click="signUp(scope.row,1)">关闭报名</p>
                <p class="signUpColor" v-if="scope.row.checkState==0" @click="toExamine(scope.row,1)">关闭审核</p>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination">
        <el-pagination
          background
          @current-change="currentChange"
          layout="prev, pager, next"
          :page-size="10"
          :current-page="page"
          :total="total">
        </el-pagination>
      </div>
    </div>

    <recruitmentDetails :recruitId="recruitId" v-if="dialogVisible" v-model="dialogVisible" />
  </div>
</template>
<script>
import {listRecruitByPage,updateRecruit} from '@/api/recruitmentMatters/recruitmentMatters';
import recruitmentDetails from '@/views/recruitmentMatters/recruitmentDetails'
export default {
  components:{
    recruitmentDetails
  },
  data() {
    return {
      title:'',
      total:0,
      page:1,
      tableData:[],
      dialogVisible:false,
      recruitId:''
    }
  },
  mounted() {
    this.getData()
  },
  methods:{
    searchFun(){
      this.page = 1;
      this.getData()
    },
    // 获取列表
    getData(){
      let data = {
        page:this.page,
        limit:10,
        title:this.title
      };
      listRecruitByPage(data).then(res=>{
        this.tableData = res.data.rows.map(item=>{
          if(item.beginTime&&item.endTime){
           
            let bIndex = item.beginTime.lastIndexOf(':');
            let bigin = item.beginTime.substring(0,bIndex).replace(/-/g,'.');
            let eIndex = item.endTime.lastIndexOf(':');
            let end = item.endTime.substring(0,eIndex).replace(/-/g,'.');
            
            item.time = bigin+' ~ '+end
          }
          return item
        });
        this.total = res.data.total;
      })
    },
    // 查看
    seeFun(row){
      this.recruitId = row.id;
      this.dialogVisible = true
    },
    // 报名
    signUp(row,type){
      let data = {
        id:row.id,
        applyState:type
      }
      updateRecruit(data).then(res=>{
        if(res.status == 200){
          this.getData()
        }
      })
    },
    // 审核
    toExamine(row,type){
      let data = {
        id:row.id,
        checkState:type
      }
      updateRecruit(data).then(res=>{
        if(res.status == 200){
          this.getData()
        }
      })
    },
    // 分页
    currentChange(val){
      this.page = val;
      this.getData()
    },
  }
}
</script>
<style lang="scss" scoped>
  .recruitmentMattersBox{
    .searchBox{
      display: flex;
      justify-content: space-between;
      padding: 20px;
      .searchLeft{
        ul{
          display: flex;
          li{
            display: flex;
            align-items: center;
            margin-right: 30px;

            .searchTitle{
              width: 120px;
              text-align: right;
            }
            .searchStatus{
              width: 50px;
              text-align: right;
            }
          }
        }
      }

      .searchRight{
        .addBtn{
          background: #bf162a;
          color: #fff;
          border: none;
        }
      }
    }

    .recruitmentMattersContent{
      margin-top: 20px;
      .signUpFlex{
        display: flex;
        justify-content: center;
        .signUpColor{
          color: rgb(17, 166, 234);
          cursor: pointer;
          margin-right: 10px;
        }
      }
    }
  }
</style>
<template>
  <div>
    <el-dialog :visible.sync="dialogVisible" top="80px" custom-class="noticeDetailsBox" width="80%" :show-close="false"
    :close-on-click-modal="false" :close-on-press-escape="false">
      <div class="tableBox">
        <el-table :data="tableData" style="width: 100%" max-height="500">
          <el-table-column align="center" prop="unit" label="单位" width="120"></el-table-column>
          <el-table-column align="center" prop="name" label="岗位名称" width="120"></el-table-column>
          <el-table-column align="center" prop="describea" label="岗位简介" width="200"></el-table-column>
          <el-table-column align="center" prop="type" label="电子邮件" width="120"></el-table-column>
          <el-table-column align="center" prop="gwjb" label="岗位级别" width="120"></el-table-column>
          <el-table-column align="center" prop="positionNum" label="招聘人数" width="100"></el-table-column>
          <el-table-column align="center" prop="censusRegisterRequire" label="户籍要求" width="120"></el-table-column>
          <el-table-column align="center" prop="educationBackgroundRequire" label="学历要求" width="140"></el-table-column>
          <el-table-column align="center" prop="diplomaRequire" label="学位要求" width="140"></el-table-column>
          <el-table-column align="center" prop="majorRequire" label="专业要求" width="140"></el-table-column>
          <el-table-column align="center" prop="ageRequire" label="年龄" width="100"></el-table-column>
          <el-table-column align="center" prop="politicalLandscapeRequire" label="政治面貌" width="100"></el-table-column>
          <el-table-column align="center" prop="otherRequire" label="其他条件" width="140"></el-table-column>
          <el-table-column align="center" prop="needInterview" label="是否组织面试" width="140">
            <template slot-scope="scope">
              <div>
                <span>{{scope.row.needInterview==1?'否':'是'}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="planScale" label="计划聘用人数与面试人选确定比例" width="100"></el-table-column>
          <el-table-column align="center" prop="positionPhone" label="联系电话" width="100"></el-table-column>
          <el-table-column align="center" prop="remark" label="备注" width="150"></el-table-column>

        </el-table>
      </div>
      <div class="closeBox">
        <el-button type="danger" class="closeBtn" @click="handleClose">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {listPosition} from '@/api/recruitPost/recruitPost'
export default {
  model:{
    prop:'dialogVisible',
    event:'change'
  },
  props:['dialogVisible','recruitId'],
  data() {
    return {
      tableData:[]
    }
  },
  mounted() {
    this.getData()
  },
  methods:{
    // 列表
    getData(){
      listPosition({recruitId:this.recruitId}).then(res=>{
        if(res.status == 200){
          this.tableData = res.data;
        }
      })
    },
    // 关闭弹框
    handleClose(){
      this.$emit('change',false)
    }
  }
}
</script>
<style lang="scss" scoped>
  .noticeHeader{
    border-bottom: 1px solid #DADADA;
    padding-bottom: 25px;
    .noticeTitle{
      text-align: center;
      font-size: 18px;
      font-weight: bold;
    }
    .noticeTime{
      font-size: 14px;
      color: #9F9F9F;
      text-align: center;
      margin-top: 20px;
    }
  }
  .editorBox{
    margin-top: 20px;
    padding: 0 70px;
  }
  .closeBox{
    display: flex;
    // justify-content: space-between;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    padding: 10px 70px;
    border-top: 1px solid #DADADA;

    .fileBox{
      ul{
        li{
          cursor: pointer;
          &:hover{
            color: #BD192C;;
          }
        }
      }
    }

    .closeBtn{
      width: 100px;
      height: 40px;
      background: rgb(189, 25, 44);
      border: none;
    }
  }
</style>
